import { InferSelectModel, relations } from "drizzle-orm";
import {
  boolean,
  foreignKey,
  integer,
  json,
  numeric,
  pgEnum,
  pgTable,
  text,
  timestamp,
  uniqueIndex,
} from "drizzle-orm/pg-core";

export const liveStamping = pgEnum("LiveStamping", [
  "none",
  "cancellation",
  "regular",
]);
export const LiveStamping = {
  none: "none",
  cancellation: "cancellation",
  regular: "regular",
} as const;
// eslint-disable-next-line no-redeclare
export type LiveStamping = (typeof LiveStamping)[keyof typeof LiveStamping];

export const orderStatus = pgEnum("OrderStatus", [
  "OrderPending",
  "OrderProcessing",
  "OrderMailing",
  "OrderDelivered",
  "OrderFailed",
  "OrderRequiresPayment",
  "OrderUndeliverable",
  "OrderCancelled",
]);
export const OrderStatus = {
  OrderPending: "OrderPending",
  OrderProcessing: "OrderProcessing",
  OrderMailing: "OrderMailing",
  OrderDelivered: "OrderDelivered",
  OrderRequiresPayment: "OrderRequiresPayment",
  OrderUndeliverable: "OrderUndeliverable",
  OrderCancelled: "OrderCancelled",
  OrderFailed: "OrderFailed",
} as const;
// eslint-disable-next-line no-redeclare
export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];

export const recipientStatus = pgEnum("RecipientStatus", [
  "Pending",
  "EnRoute",
  "Redirected",
  "Delivered",
  "Returned",
  "Undeliverable",
  "QRScanned",
]);
export const RecipientStatus = {
  Pending: "Pending",
  EnRoute: "EnRoute",
  Redirected: "Redirected",
  Delivered: "Delivered",
  Returned: "Returned",
  Undeliverable: "Undeliverable",
  QRScanned: "QRScanned",
} as const;
// eslint-disable-next-line no-redeclare
export type RecipientStatus =
  (typeof RecipientStatus)[keyof typeof RecipientStatus];

export const invoiceStatus = pgEnum("InvoiceStatus", ["Pending", "Paid"]);
export const InvoiceStatus = {
  Pending: "Pending",
  Paid: "Paid",
} as const;
// eslint-disable-next-line no-redeclare
export type InvoiceStatus = (typeof InvoiceStatus)[keyof typeof InvoiceStatus];

export const userType = pgEnum("UserType", ["COMPANY", "LOCATION"]);
export const UserType = {
  COMPANY: "COMPANY",
  LOCATION: "LOCATION",
} as const;
// eslint-disable-next-line no-redeclare
export type UserType = (typeof UserType)[keyof typeof UserType];

export const planType = pgEnum("PlanType", ["BASIC", "PRO"]);
export const PlanType = {
  BASIC: "BASIC",
  PRO: "PRO",
} as const;
// eslint-disable-next-line no-redeclare
export type PlanType = (typeof PlanType)[keyof typeof PlanType];

export const envelopeType = pgEnum("EnvelopeType", [
  "doubleWindow",
  "fullWindow",
  "Regular",
  "BiFold",
]);

export const EnvelopeType = {
  doubleWindow: "doubleWindow",
  fullWindow: "fullWindow",
  Regular: "Regular",
  BiFold: "BiFold",
} as const;
// eslint-disable-next-line no-redeclare
export type EnvelopeType = (typeof EnvelopeType)[keyof typeof EnvelopeType];

export const envelopeFont = pgEnum("EnvelopeFont", [
  "Standard",
  "Bradley Hand",
  "Blackjack",
  "FG Cathies Hand",
  "Crappy Dan",
  "Dakota",
  "Jenna Sue",
]);

export const EnvelopeFont = {
  Standard: "Standard",
  BradleyHand: "Bradley Hand",
  Blackjack: "Blackjack",
  FGCathiesHand: "FG Cathies Hand",
  CrappyDan: "Crappy Dan",
  Dakota: "Dakota",
  JennaSue: "Jenna Sue",
} as const;

// eslint-disable-next-line no-redeclare
export type EnvelopeFont = (typeof EnvelopeFont)[keyof typeof EnvelopeFont];

export const envelopeFontColor = pgEnum("EnvelopeFontColor", [
  "Black",
  "Blue",
  "Green",
]);

export const EnvelopeFontColor = {
  Black: "Black",
  Blue: "Blue",
  Green: "Green",
} as const;

// eslint-disable-next-line no-redeclare
export type EnvelopeFontColor =
  (typeof EnvelopeFontColor)[keyof typeof EnvelopeFontColor];

export const users = pgTable(
  "User",
  {
    id: text("id").primaryKey().notNull(),
    integrationId: integer("integrationId"),
    type: userType("type").notNull(),
    createdAt: timestamp("createdAt").defaultNow().notNull(),
    updatedAt: timestamp("updatedAt")
      .defaultNow()
      .notNull()
      .$onUpdate(() => new Date()),
    accessToken: text("accessToken"),
    refreshToken: text("refreshToken"),
    active: boolean("active").default(true).notNull(),
    pendingVerification: boolean("pendingVerification")
      .default(false)
      .notNull(),
    setupComplete: boolean("setupComplete").default(false).notNull(),
    apiKey: text("apiKey"),
    apiSecret: text("apiSecret"),
    name: text("name"),
    email: text("email"),
    companyId: text("companyId"),
    orderCount: integer("orderCount").default(0).notNull(),
    sandboxApiKey: text("sandboxApiKey"),
    sandboxApiSecret: text("sandboxApiSecret"),
    address: text("address"),
    address2: text("address2"),
    city: text("city"),
    companyName: text("companyName"),
    firstName: text("firstName"),
    lastName: text("lastName"),
    state: text("state"),
    zipCode: text("zipCode"),
    planType: planType("planType").default("BASIC").notNull(),

    overrideMailingAddressFields: boolean("overrideMailingAddress")
      .notNull()
      .default(false),
    mailingAddressField: text("mailingAddressField")
      .notNull()
      .default("address1"),
    mailingCityField: text("mailingCityField").notNull().default("city"),
    mailingStateField: text("mailingStateField").notNull().default("state"),
    mailingZipCodeField: text("mailingZipCodeField")
      .notNull()
      .default("postalCode"),
  },
  (table) => [
    foreignKey({
      columns: [table.companyId],
      foreignColumns: [table.id],
      name: "User_companyId_fkey",
    })
      .onUpdate("cascade")
      .onDelete("set null"),
  ],
);

export type User = InferSelectModel<typeof users>;

export const userRelations = relations(users, ({ many, one }) => ({
  company: one(users, {
    relationName: "companyLocation",
    fields: [users.companyId],
    references: [users.id],
  }),
  locations: many(users, {
    relationName: "companyLocation",
  }),
  orders: many(orders),
  sessions: many(sessions),
  notifications: many(notifications),
  invoices: many(invoices),
  designMappings: many(designMappings),
  designConfigs: many(designConfig),
  feedback: many(feedback),
  customFields: one(customFields),
  triggers: many(triggers),
  config: one(configs),
}));

export const orders = pgTable("Order", {
  id: text("id").primaryKey().notNull(),
  createdAt: timestamp("createdAt").defaultNow().notNull(),
  updatedAt: timestamp("updatedAt")
    .defaultNow()
    .notNull()
    .$onUpdate(() => new Date()),
  externalBatchId: integer("externalBatchId"),
  externalOrderId: integer("externalOrderId"),
  userId: text("userId")
    .notNull()
    .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" }),
  status: orderStatus("status").default("OrderPending").notNull(),
  designId: integer("designId").notNull().default(0),
  size: text("size").notNull(),
  class: text("class").notNull(),
  proofBack: text("proofBack"),
  proofFront: text("proofFront"),
  proofPDF: text("proofPDF"),
});

export type Order = InferSelectModel<typeof orders>;

export const orderRelations = relations(orders, ({ one, many }) => ({
  user: one(users, { fields: [orders.userId], references: [users.id] }),
  recipients: many(recipients),
}));

export const recipients = pgTable(
  "Recipient",
  {
    id: text("id").primaryKey().notNull(),
    contactId: text("contactId").notNull(),
    createdAt: timestamp("createdAt").defaultNow().notNull(),
    updatedAt: timestamp("updatedAt")
      .defaultNow()
      .notNull()
      .$onUpdate(() => new Date()),
    orderId: text("orderId")
      .notNull()
      .references(() => orders.id, {
        onDelete: "cascade",
        onUpdate: "cascade",
      }),
    status: recipientStatus("status"),
    firstName: text("firstName").notNull(),
    lastName: text("lastName").notNull(),
    address: text("address").notNull(),
    city: text("city").notNull(),
    state: text("state").notNull(),
    zipCode: text("zipCode").notNull(),
    qrCodeScannedAt: timestamp("qrCodeScannedAt"),
    company: text("company"),
    variables: json("variables").default(null).$type<
      {
        key: string;
        value: string;
      }[]
    >(),
  },
  (table) => [
    uniqueIndex("Recipient_orderId_contactId_key").on(
      table.contactId,
      table.orderId,
    ),
  ],
);

export type Recipient = InferSelectModel<typeof recipients>;

export const recipientRelations = relations(recipients, ({ one }) => ({
  order: one(orders, { fields: [recipients.orderId], references: [orders.id] }),
}));

export const sessions = pgTable("Session", {
  id: text("id").primaryKey().notNull(),
  userId: text("userId")
    .notNull()
    .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" }),
  activeUserId: text("activeUserId"),
  activeUserType: text("activeUserType"),
  expiresAt: timestamp("expiresAt").notNull(),
});

export type Session = InferSelectModel<typeof sessions>;

export const sessionRelations = relations(sessions, ({ one }) => ({
  user: one(users, { fields: [sessions.userId], references: [users.id] }),
}));

export const notifications = pgTable("Notification", {
  id: text("id").primaryKey().notNull(),
  createdAt: timestamp("createdAt").defaultNow().notNull(),
  updatedAt: timestamp("updatedAt")
    .defaultNow()
    .notNull()
    .$onUpdate(() => new Date()),
  userId: text("userId")
    .notNull()
    .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" }),
  title: text("title").notNull().default("New Notification"),
  message: text("message").notNull(),
  href: text("href"),
  read: boolean("read").default(false).notNull(),
});

export type Notification = InferSelectModel<typeof notifications>;

export const notificationRelations = relations(notifications, ({ one }) => ({
  user: one(users, { fields: [notifications.userId], references: [users.id] }),
}));

export const invoices = pgTable("Invoice", {
  id: text("id").primaryKey().notNull(),
  status: invoiceStatus("status").default("Pending").notNull(),
  createdAt: timestamp("createdAt").defaultNow().notNull(),
  updatedAt: timestamp("updatedAt")
    .defaultNow()
    .notNull()
    .$onUpdate(() => new Date()),
  userId: text("userId")
    .notNull()
    .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" }),
  externalBatchId: integer("externalBatchId").notNull(),
  invoiceTotal: numeric("invoiceTotal", { precision: 10, scale: 4 }),
  invoiceUrl: text("invoiceUrl"),
});

export type Invoice = InferSelectModel<typeof invoices>;

export const invoiceRelations = relations(invoices, ({ one }) => ({
  user: one(users, { fields: [invoices.userId], references: [users.id] }),
}));

export const designMappings = pgTable(
  "DesignMapping",
  {
    id: text("id").primaryKey().notNull(),
    createdAt: timestamp("createdAt").defaultNow().notNull(),
    updatedAt: timestamp("updatedAt")
      .defaultNow()
      .notNull()
      .$onUpdate(() => new Date()),
    userId: text("userId")
      .notNull()
      .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" }),
    designId: integer("designId").notNull(),
    mappings: json("mappings").notNull().$type<
      {
        key: string;
        value: string;
      }[]
    >(),
  },
  (table) => [
    uniqueIndex("designMapping_userId_designId").on(
      table.userId,
      table.designId,
    ),
  ],
);

export type DesignMapping = InferSelectModel<typeof designMappings>;

export const designMappingRelations = relations(designMappings, ({ one }) => ({
  user: one(users, { fields: [designMappings.userId], references: [users.id] }),
}));

export const designConfig = pgTable(
  "DesignConfig",
  {
    id: text("id").primaryKey().notNull(),
    createdAt: timestamp("createdAt").defaultNow().notNull(),
    updatedAt: timestamp("updatedAt")
      .defaultNow()
      .notNull()
      .$onUpdate(() => new Date()),
    userId: text("userId")
      .notNull()
      .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" }),
    designId: integer("designId").notNull(),
    envelopeType: envelopeType("envelopeType")
      .default("doubleWindow")
      .notNull(),
    envelopeFont: envelopeFont("envelopeFont").default("Standard").notNull(),
    envelopeFontColor: envelopeFontColor("envelopeFontColor")
      .default("Black")
      .notNull(),
    tagline: text("tagline").default("none").notNull(),
    customTagline: text("customTagline").default("").notNull(),
    liveStamping: liveStamping("liveStamping").default("none").notNull(),
    printInColor: boolean("printInColor").default(true).notNull(),
    printOnBothSides: boolean("printOnBothSides").default(true).notNull(),
    insertAddressingPage: boolean("insertAddressingPage")
      .default(false)
      .notNull(),
    includeReturnAddress: boolean("includeReturnAddress")
      .default(true)
      .notNull(),
  },
  (table) => [
    uniqueIndex("userId_designId_designConfig").on(
      table.userId,
      table.designId,
    ),
  ],
);

export type DesignConfig = InferSelectModel<typeof designConfig>;

export const designConfigRelations = relations(designConfig, ({ one }) => ({
  user: one(users, { fields: [designConfig.userId], references: [users.id] }),
}));

export const feedbackCategory = pgEnum("FeedbackCategory", [
  "General",
  "Bug",
  "FeatureRequest",
]);
export const FeedbackCategory = {
  General: "General",
  Bug: "Bug",
  FeatureRequest: "FeatureRequest",
} as const;
// eslint-disable-next-line no-redeclare
export type FeedbackCategory =
  (typeof FeedbackCategory)[keyof typeof FeedbackCategory];

export const feedback = pgTable("Feedback", {
  id: text("id").primaryKey().notNull(),
  createdAt: timestamp("createdAt").defaultNow().notNull(),
  updatedAt: timestamp("updatedAt")
    .defaultNow()
    .notNull()
    .$onUpdate(() => new Date()),
  userId: text("userId")
    .notNull()
    .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" }),
  category: feedbackCategory("category").notNull(),
  feedback: text("feedback").notNull(),
});

export type Feedback = InferSelectModel<typeof feedback>;

export const feedbackRelations = relations(feedback, ({ one }) => ({
  user: one(users, { fields: [feedback.userId], references: [users.id] }),
}));

type customField = {
  id: string;
  name: string;
  fieldKey: string;
  placeholder: string;
  dataType: string;
  position: number;
  picklistOptions: string[];
  picklistImageOptions: string[];
  isAllowedCustomOption: boolean;
  isMultiFileAllowed: boolean;
  maxFileLimit: number;
  locationId: string;
  model: string;
};

export const customFields = pgTable("CustomFields", {
  id: text("id").primaryKey().notNull(),
  createdAt: timestamp("createdAt").defaultNow().notNull(),
  updatedAt: timestamp("updatedAt")
    .defaultNow()
    .notNull()
    .$onUpdate(() => new Date()),
  userId: text("userId")
    .notNull()
    .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" })
    .unique(),
  customFields: json("customFields").notNull().$type<customField[]>(),
});

export type CustomFields = InferSelectModel<typeof customFields>;

export const customFieldsRelations = relations(customFields, ({ one }) => ({
  user: one(users, { fields: [customFields.userId], references: [users.id] }),
}));

export const triggerType = pgEnum("TriggerType", ["Tracking", "QRScan"]);
export const TriggerType = {
  TRACKING: "Tracking",
  QR_SCAN: "QRScan",
} as const;
// eslint-disable-next-line no-redeclare
export type TriggerType = (typeof TriggerType)[keyof typeof TriggerType];

export const triggers = pgTable("Triggers", {
  id: text("id").primaryKey().notNull(),
  createdAt: timestamp("createdAt").defaultNow().notNull(),
  updatedAt: timestamp("updatedAt")
    .defaultNow()
    .notNull()
    .$onUpdate(() => new Date()),
  userId: text("userId")
    .notNull()
    .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" }),
  type: triggerType("type").notNull(),
  filters: json("triggerFilters").notNull().$type<
    {
      field: string;
      id: string;
      operator: string;
      title: string;
      type: string;
      value?: string;
    }[]
  >(),
  url: text("url").notNull(),
});

export type Trigger = InferSelectModel<typeof triggers>;

export const triggerRelations = relations(triggers, ({ one }) => ({
  user: one(users, { fields: [triggers.userId], references: [users.id] }),
}));

export const configs = pgTable("Config", {
  id: text("id").primaryKey().notNull(),
  createdAt: timestamp("createdAt").defaultNow().notNull(),
  updatedAt: timestamp("updatedAt")
    .defaultNow()
    .notNull()
    .$onUpdate(() => new Date()),
  userId: text("userId")
    .notNull()
    .references(() => users.id, { onDelete: "cascade", onUpdate: "cascade" })
    .unique(),
  enableWhiteLabel: boolean("enableWhiteLabel").default(false).notNull(),
  logo: text("logo"),
  logoWidth: integer("logoWidth").default(0).notNull(),
  logoHeight: integer("logoHeight").default(0).notNull(),
  brandColor: text("brandColor"),
  welcomeMessage: text("welcomeMessage"),
  templateGalleryTags: text("templateGalleryTags"),
});

export type Config = InferSelectModel<typeof configs>;

export const configRelations = relations(configs, ({ one }) => ({
  user: one(users, { fields: [configs.userId], references: [users.id] }),
}));
